
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import StockEnvios from "../../../store/entities/OZONE/stockEnvio";
import Modal from "../../../components/Modal.vue";
import Campana from "../../../store/entities/OZONE/campana";
import ServicioMensajeria from "../../../store/entities/OZONE/servicioMensajeria";
import Charlie from "../../../store/entities/OZONE/charlie";
import Participacion from "../../../store/entities/OZONE/participacion";

class PageStockRequest extends PageRequest {
  campana: Campana;
  stock: boolean;
  urgent: boolean;
  Ucc: boolean;
  regiones: string;
  servicioMensajeria: ServicioMensajeria;
  stockEnvios: Array<StockEnvios>;
  filtroCampoId: number;
  filtroCampoTipo: number;
  filtroCampoValor: string;
  filtroCampoNombre: string;
  filtroFechaInicio: Date;
  filtroFechaFin: Date;
}
class PageCamposRequest extends PageRequest {
  campanaId: number;
}

@Component({
  components: { Modal },
})
export default class StockModal extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: String }) id: string;
  @Prop({ type: Number }) entityId: number;
  @Prop({ type: String }) title: string;

  //filters
  pagerequest: PageStockRequest = new PageStockRequest();
  camposRequest: PageCamposRequest = new PageCamposRequest();
  pagination: any = {};
  prueba: any = {};
  viewModalShow = false;
  entityChangeSetId: number = 0;
  stockEnvios: Array<StockEnvios> = [];
  stockAlmacenMayor0_: Array<StockEnvios> = [];
  filtro: string = null;
  filtroNombre: string = null;
  filtroCampoId: number = -1;
  filtroCampoTipo: number = -1;
  filtroCampoValor: string = null;
  filtroCampoNombre: string = null;
  filtroFechaInicio: Date = new Date();
  filtroFechaFin: Date = new Date();
  mensaje: boolean = false;
  allownostock: boolean = false;
  urgent: boolean = false;
  Ucc: boolean = false;
  servicioMensajeriaId: number = -1;
  regiones: string = "all";
  conStock: boolean = false;
  charlie: Charlie = new Charlie();
  campana: Campana = new Campana();
  numEnviosGenerar: number = 0;
  participacionesId: number [];
  enviosId: number [];
  camposByCampana: Array<any> = [];

  get list() {
    return this.$store.state.envio.stockEnvios;
  }
  get loading() {
    return this.$store.state.envio.loading;
  }
  get totalCount() {
    return this.$store.state.envio.totalCount;
  }

  setStock(e) {
    this.conStock = e.target.checked;
    this.getpage();
  }

  setUrgent(e) {
    this.urgent = e.target.checked;
    this.getpage();
  }

  setUCC(e) {
    this.Ucc = e.target.checked;
    this.getpage();
  }

  setServicio(e){
    this.servicioMensajeriaId = parseInt(e);
    this.getpage();
  }

  setEnviosGenerar(e, articulo) {
    var envio = this.stockEnvios.find((o) => o.articulo == articulo);
    if (envio) {
      envio.enviosGenerar = e.value;
    }
  }

  async create() {
    
  }

  handleBlur(e, record) {
    var envio = this.$store.state.envio.stockEnvios.find(
      (o) => o.enviosGenerar == e
    );
    if (
      envio.enviosGenerar > envio.totalPendientes ||
      (envio.stock_Almacen < envio.enviosGenerar && !this.allownostock)
      || e < 1
    ) {
      this.mensaje = true;
    } else {
      this.mensaje = false;
      this.numEnviosGenerar = e;
    }
  }

  handleMensajeriaCharlie(e) {
    this.charlie.mensajeriaId = e;
  }

  handlePlantillaOut(e) {
    this.charlie.plantillaOutId = e;
  }

  handleMenuClick(e) {
    this.filtro = e.key;
    this.filtroNombre = e.item.value;
  }

  handleCampo(e) {
    this.filtroCampoTipo = this.camposByCampana.filter((x) => x.id == e).map((sel) => sel.tipo)[0];
    this.filtroCampoNombre = this.camposByCampana.filter((x) => x.id == e).map((sel) => sel.text)[0];
    this.filtroCampoId = e
  }

  handleCampoValor(e) {
    this.filtroCampoValor = e;
    this.getpage();
  }

  handleFechaInicio(e) {
    this.filtroFechaInicio = e;
    this.filtroCampoValor = "fecha"
    this.getpage();
  }

  handleFechaFin(e) {
    this.filtroFechaFin = e;
    this.filtroCampoValor = "fecha"
    this.getpage();
  }

  pageChange(page: number) {
    this.$store.commit("envio/setCurrentPage", page);
    this.getpage();
  }

  pagesizeChange(pagesize: number) {
    this.$store.commit("envio/setPageSize", pagesize);
    this.getpage();
  }

  async getpage() {
    let campana = new Campana();
    campana.id = this.entityId;
    this.pagerequest.campana = campana;

    this.pagerequest.filtroCampoId = this.filtroCampoId != null && this.filtroCampoId != -1 ? this.filtroCampoId : -1;
    this.pagerequest.filtroCampoTipo = this.filtroCampoTipo != null && this.filtroCampoTipo != -1 ? this.filtroCampoTipo : -1;
    this.pagerequest.filtroCampoValor = this.filtroCampoValor != null && this.filtroCampoValor != "" ? this.filtroCampoValor : null;
    this.pagerequest.filtroCampoNombre = this.filtroCampoNombre != null && this.filtroCampoNombre != "" ? this.filtroCampoNombre : null;
    this.pagerequest.filtroFechaInicio = this.filtroFechaInicio;
    this.pagerequest.filtroFechaFin = this.filtroFechaFin;
    this.filtroCampoValor = null

    this.pagerequest.stock = this.conStock;
    this.pagerequest.urgent = this.urgent;
    this.pagerequest.Ucc = this.Ucc;
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    
    if (this.regiones != "all") this.pagerequest.regiones = this.regiones;
    else this.pagerequest.regiones = null;

    if (this.servicioMensajeriaId >= 0) {
      let servicioMensajeria = new ServicioMensajeria();
      servicioMensajeria.id = this.servicioMensajeriaId;
      this.pagerequest.servicioMensajeria = servicioMensajeria;
    }
    
    await this.$store.dispatch({
      type: "envio/getStock",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.envio.totalCount;
    pagination.pageSize = this.$store.state.envio.pageSize;
    pagination.current = this.$store.state.envio.currentPage;
    this.pagination = pagination;
  }

  async getCamposByCampana() {
    this.camposRequest.campanaId = this.pagerequest.campana.id;
    this.camposRequest.maxResultCount = maxResults.maxResultCount;
    this.camposRequest.skipCount = maxResults.skipCount;
    await this.$store.dispatch({
      type: "campana/getAllCampos",
      data: this.camposRequest,
    });

    this.camposByCampana = this.$store.state.campana.camposList;
    this.getCamposByParticipacion();
  }

  async getCamposByParticipacion() {
    //Crea un objeto "Participación" para después recorrer sus atributos
    //y añadir sus nombres al select
    var p = new Participacion();
    p.nombre = "";
    p.apellido1 = "";
    p.apellido2 = "";
    p.email = "";
    p.fechaNacimiento = "";
    p.sexo = "";
    p.telefono = "";
    p.dNI = "";
    let lastId = 1000;

    //Recorre el objeto y añade al array del select
    let cont = 1;
    for (var campo in p) {
      if(campo == "fechaNacimiento"){
        this.camposByCampana.push({
          id: lastId + cont,
          text: "FechaNacimiento",
          nombre: "Fecha de nacimiento",
          tipo: 4
        })
      }
      else if(campo == "dNI"){
        this.camposByCampana.push({
          id: lastId + cont,
          text: "DNI",
          nombre: "DNI",
          tipo: 1
        })
      }
      else{
        this.camposByCampana.push({
          id: lastId + cont,
          text: campo.substring(0,1).toUpperCase() + campo.substring(1),
          nombre: campo.substring(0,1).toUpperCase() + campo.substring(1),
          tipo: 1
        });
      }
      cont++;
    }
  }

  get pageSize() {
    return this.$store.state.envio.pageSize;
  }

  get currentPage() {
    return this.$store.state.envio.currentPage;
  }

  set currentPage(page) {
    this.$store.commit("envio/setCurrentPage", page);
    this.getpage();
  }

  onChange = async (pagination, filters, articulo) => {
    this.$store.commit("envio/setCurrentPage", pagination.current);
    this.getpage();
  };

  shown() {

  }

  close() {
    this.$emit("close");
    this.$emit("input", false);
  }

  async generarfichero() {
    this.pagerequest.stockEnvios = this.list;
    this.stockEnvios = this.list;
    this.stockEnvios[0].filtro = this.filtro;

    await this.$store.dispatch({
      type: "envio/generarFichero",
      data: this.pagerequest,
    });

    let fichero = this.$store.state.envio.download;
    this.charlie.enviosId = fichero.listaEnviosId;
    this.charlie.participacionesId = fichero.listaParticipacionesId;

    this.close();
  }

  async generarFicheroCharlie() {
    await this.$store.dispatch({
      type: "charlie/generarFicheroCharlie",
      data: this.charlie,
    });
  }

  async obtieneCampana(campId) {
    await this.$store.dispatch({
      type: "campana/getForView",
      id: campId,
    });
    this.campana = this.$store.state.campana.editCampana;
    this.charlie.nomCamp = this.campana.nombre;
    this.charlie.ekonCamp = this.campana.axapta;
    this.charlie.campId = campId;
  }

  async obtieneCliente(clienteId) {
    await this.$store.dispatch({
      type: "cliente/get",
      id: clienteId,
    });
    this.charlie.nomCliente = this.$store.state.cliente.editCliente.nombre;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async save() {
    if (this.numEnviosGenerar > 0){
      //Este método llama a la generación del fichero de envío desde Ozone en lugar que desde Charlie
      await this.generarfichero();
      await this.obtieneCampana(this.entityId);
      await this.obtieneCliente(this.campana.clienteId);
      await this.generarFicheroCharlie();    
      this.$emit("input", false);
    }
    else {
      this.mensaje = true;
    }
  }

  getTagByDiff(pending, stock){

    if(stock == 0){
        return "red";
    }

    var diff = stock - pending;

    if(diff == 0){
        return "blue";
    }
    else if( diff > 0){
        return 'green'
    }
    else if (diff > -10 ){
        return 'orange'
    }
    else {
        return 'red'
    }

  }

  async created() {
     this.$store.commit("envio/setPageSize", 50);
     await this.getpage();
     await this.getCamposByCampana();
  }

  columns = [
  {
      title: this.L("Articulo"),
      scopedSlots: { customRender: "func" },
      fixed: 'left',
    },
    {
      title: this.L("Pendientes de Envio"),
      scopedSlots: { customRender: "pending" },
      fixed: 'left',
      align: "center",
    },
    {
      title: this.L("Stock en Almacen"),
      dataIndex: "stock_Almacen",
      scopedSlots: { customRender: "stock" },
      fixed: 'left',
      align: "center",
    },
    {
      title: this.L("Envios a generar"),
      scopedSlots: { customRender: "X" },
    },
  ];

  lotecolumns = [
    {
      title: this.L("Articulo"),
      scopedSlots: { customRender: "func" },
    },
    {
      title: this.L("Cantidad"),
      scopedSlots: { customRender: "pending" },
      align: "center",
    },
    {
      title: this.L("Cantidad Total"),
      scopedSlots: { customRender: "total" },
      align: "center",
    },
    {
      title: this.L("Stock en Almacen"),
      dataIndex: "stock_Almacen",
      scopedSlots: { customRender: "stock" },
      align: "right",
    },
  ];
}
